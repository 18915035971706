import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from "@loadable/component"
// import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import H2 from '../../core/H2';
import P from '../../core/P';
const Slider = loadable(() => import("react-slick"))

export default function ZDemo() {
  const data = useStaticQuery(graphql`
    query AwardQuery {
      allWpAward {
        nodes {
          AwardsCPT {
            link
            id
            image {
                  mediaItemUrl
                  srcSet
                  altText
                  mediaDetails {
                    width
                    height
                  }
                }
          }
          title
        }
      }
    }
  `);
  const awards_node = data?.allWpAward?.nodes;
  const settings = {
    className: "center",
    autoplay: true,
    autoplaySpeed: 0,
    loopIfEqual: false,
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnHover: true,
    focusOnSelect: false,
    useTransform: false,
    arrows: false,
    cssEase: "linear",
    centerMode: true,
    centerPadding: '0px',
    swipe: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const sortawards=awards_node.sort((a, b) => a?.AwardsCPT?.id - b?.AwardsCPT?.id);

  return (

    <div className="py-20 md:px-6 px-4">
      <div className=" max-w-5xl mx-auto  ">
        <H2 title={'Our Awards & Recognition'} className="text-center" />
        <P title={`WebMob Technologies have delivered solutions that have exceeded our clients' expectations all around the world, and Clutch has recognized and appreciated our dedication and quality of work by recognizing us as "Top Developers and Global Leading B2B Firm" for SIX Consecutive Years.`} className="text-center text-shark-400" />
        <div className="md:flex w-full mx-auto lg:flex justify-center max-w-2xl text-center items-center pt-4 md:pt-8">
          <div className="md:w-2/5">
            <a href="https://clutch.co/profile/webmob-technologies#summary">
              <p className="text-2xl pr-3 font-semibold text-shark-500">
                41 Review on Clutch
              </p>
            </a>
          </div>
          <div className=" font-semibold pt-4 md:pt-0 md:w-3/5">
            <a href="https://clutch.co/profile/webmob-technologies#summary">
              <img
                className="h-full w-full"
                src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Clutch_Ratings.webp"
                alt="img"
                title="our awards"
                loading="lazy"
                height={118}
                width={700}
              ></img>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-transperent max-w-6xl  mx-auto overflow-hidden pt-8 relative">
        <Slider
          {...settings}
          className="custom-slick-slider slider slider__container mx-auto "
        >
          {sortawards &&
            sortawards.map(({ title, AwardsCPT: { link: awardsLink, id,image: { mediaItemUrl, mediaDetails, altText } = {} } = {} } = {}, index) => (
            
              <div className="p-2 lg:p-4 lg:px-4" key={index}>  
                {awardsLink ? (
                  <a href={awardsLink} target='_blank' rel="noreferrer">
                    <div className="w-full bg-white shadow-lg rounded-lg" key={index}>
                      <div className="w-auto h-48 justify-self-center self-center overflow-hidden relative py-5">
                        {mediaItemUrl && (
                          <img
                            src={mediaItemUrl}
                            alt={altText ? altText : "our awards"}
                            title={altText ? altText : "our awards"}
                            loading="lazy"
                            height={mediaDetails?.height}
                            width={mediaDetails?.width}
                            className="w-auto mx-auto h-full"
                          />
                        )}
                      </div>
                      {title && (
                        <div className="w-full flex items-center place-content-center h-10">
                          <p className="text-xs pb-3 px-3 text-center text-shark-400 font-bold w-44">
                            {title}
                          </p>
                        </div>
                      )}
                    </div>
                  </a>
                ) : (
                  <div className="w-full bg-white shadow-lg rounded-lg" key={index}>
                    <div className="w-auto h-48 justify-self-center self-center overflow-hidden relative py-5">
                      {mediaItemUrl && (
                        <img
                          src={mediaItemUrl}
                          alt={altText ? altText : "our awards"}
                          title={altText ? altText : "our awards"}
                          loading="lazy"
                          height={mediaDetails?.height}
                          width={mediaDetails?.width}
                          className="w-auto mx-auto h-full"
                        />
                      )}
                    </div>
                    {title && (
                      <div className="w-full flex items-center place-content-center h-10">
                        <p className="text-xs pb-3 px-3 text-center text-shark-400 font-bold w-44">
                          {title}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
}
